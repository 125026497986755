export default (data = {}) => {
    return {
        category: null,
        product_name: null,
        price: 0,
        unique: false,
        selected: false,
        ...data
    }
}
