<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-fade-transition"
      scrollable
      max-width="480px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark width="100%">
            <v-tabs v-model="tab">
              <v-tab key="product"> Producto </v-tab>
              <v-tab key="options"> Opciones </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-icon @click="close()"> mdi-close </v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item key="product">
              <v-form v-model="valid" ref="itemForm" @submit="item_submit">
                <v-card flat tile>
                  <v-card-title class="mb-3" align="center" justify="center">
                    <v-img
                      cover
                      aspect-ratio="1"
                      class="grey lighten-2"
                      :src="productPhoto"
                      max-height="250"
                    >
                      <v-fab-transition>
                        <v-btn
                          class="mx-2 btn-upload"
                          fab
                          light
                          small
                          color="secondary"
                          @click="chooseImage"
                        >
                          <v-icon dark> mdi-camera-plus-outline </v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </v-img>
                    <input
                      class="file-input"
                      ref="fileInput"
                      type="file"
                      @input="onSelectFile"
                    />
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-combobox
                          v-model="item.category"
                          id="category"
                          hide-details="auto"
                          label="Categoría"
                          :items="categories"
                          :rules="f_required"
                          outlined
                          required
                          dense
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.product_name"
                          id="product_name"
                          hide-details="auto"
                          :rules="f_required"
                          label="Nombre"
                          outlined
                          required
                          dense
                        />
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          v-model="item.price"
                          label="Precio"
                          class="centered-input"
                          hide-details="auto"
                          :rules="f_required"
                          @keypress="onlyNumber"
                          prepend-inner-icon="mdi-currency-usd"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          v-model="item.discount"
                          label="Descuento"
                          class="centered-input"
                          hide-details="auto"
                          :rules="f_required"
                          @keypress="onlyNumber"
                          prepend-inner-icon="mdi-sale"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="vueEditorCSS">
                        <vue-editor
                          v-model="item.description"
                          :editorToolbar="customToolbar"
                        ></vue-editor>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-toolbar>
                  <v-btn
                    text
                    :disabled="newItem"
                    type="button"
                    @click="delete_itm(item)"
                  >
                    <span>Borrar</span>
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text type="submit">
                    <span>Guardar</span>
                    <v-icon>mdi-content-save-outline</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-form>
            </v-tab-item>
            <v-tab-item key="options">
              <v-card flat class="mb-6">
                <v-card-text>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="option.category"
                        hide-details="auto"
                        :rules="f_required"
                        label="Categoría"
                        outlined
                        required
                        dense
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-switch
                        hide-details
                        v-model="option.unique"
                        label="Selección multiple"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="option.product_name"
                        hide-details="auto"
                        :rules="f_required"
                        label="Producto"
                        outlined
                        required
                        dense
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="option.price"
                        hide-details="auto"
                        :rules="f_required"
                        label="Precio"
                        outlined
                        required
                        dense
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-btn block @click="addToOptions">Adicionar</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row>
                <v-col
                  cols="12"
                  v-for="(value, key) in options_selected"
                  :key="key"
                  justify="center"
                >
                  <h3 v-if="options_selected[key]">
                    Seleccione la {{ key }} que desee
                  </h3>

                  <v-data-table
                    :headers="header_selected"
                    hide-default-header
                    hide-default-footer
                    :items-per-page="-1"
                    mobile-breakpoint="0"
                    :items="selectedLst(key)"
                    class="elevation-1"
                    dense
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn icon @click="deleteOption(item)"
                        ><v-icon>mdi-delete-outline</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="pa-0"> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn
            large
            text
            color="red"
            type="button"
            @click="dialog_confirm = !dialog_confirm"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alerts" hide-overlay persistent>
      <v-card>
        <v-alert border="left" color="orange" prominent text type="warning">
          <v-row align="center">
            <v-col class="grow"> Formato de archivo o tamaño no valido. </v-col>
            <v-col class="shrink">
              <v-btn fab x-small @click="alerts = false"
                ><v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { webserver, checkUTF8 } from "../services/webserver.js";
import pOptions from "../utils/product_options.js";
import { VueEditor } from "vue2-editor";
export default {
  name: "MenuForm",
  components: { VueEditor },
  data() {
    return {
      options_selected: {},
      option: pOptions(),
      optionsForm: false,
      alerts: false,
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      reg_delete: null,
      valid: false,
      header_selected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
        },
        { text: "", align: "end", width: 10, value: "actions" },
      ],
      customToolbar: [
        // [{ font: [] }],
        // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        //  [{ header: 1 }, { header: 2 }],
        //[{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        //[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      uom_lst: [
        { value: "UN", text: "Unidad" },
        { value: "GR", text: "Gramo" },
        { value: "KG", text: "Kilogramo" },
        { value: "LB", text: "Libra" },
        { value: "LT", text: "Litro" },
        { value: "ML", text: "Mililitro" },
        { value: "GL", text: "Galon" },
        { value: "OZ", text: "Onza" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      productPhoto: null,
      headers_options: [
        {
          text: "Categoria",
          align: "center",
          value: "category",
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "start",
          sortable: true,
          value: "price",
        },
        {
          text: "",
          align: "start",
          sortable: true,
          value: "actions",
        },
      ],
      options_lst: [],
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: false,
    },
    subcategories: {
      type: Array,
      required: false,
    },
    brands: {
      type: Array,
      required: false,
    },
    suppliers: {
      type: Array,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    this.options_selected = {};
    if (this.item.product_options) {
      if (JSON.parse(this.item.product_options)) {
        this.options_selected = JSON.parse(this.item.product_options);
      } else {
        this.options_selected = {};
      }
    } else {
      this.options_selected = {};
    }
  },
  methods: {
    addToOptions() {
      if (this.options_selected[this.option.category]) {
        this.options_selected[this.option.category].push({ ...this.option });
      } else {
        this.options_selected[this.option.category] = [];
        this.options_selected[this.option.category].push({ ...this.option });
      }
      //this.item.product_options = JSON.stringify(this.options_selected);
      this.option = pOptions();
    },
    selectedLst(e) {
      return this.options_selected[e];
    },
    deleteOption(e) {
      console.log(e);
      var index = this.options_selected[e.category].indexOf(e);
      if (index !== -1) {
        this.options_selected[e.category].splice(index, 1);
      }
      if (this.options_selected[e.category].length === 0) {
        delete this.options_selected[e.category];
      }
      //e.qty = 0;
      this.option = pOptions();
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getImagePhoto: function (account, store, src) {
      var url =
        "https://smartchef.pro/photos/" + account + "/" + store + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 400 / img.width;
            const elem = document.createElement("canvas");

            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/jpeg");
            this.compressed_image = imageUrl;
            this.productPhoto = imageUrl;
            this.loading_status = false;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();

        // if (files[0].size <= 20000000) {
        //   this.loading_status = true;
        //   reader.readAsDataURL(files[0]);
        //   this.item.picture = files[0].name.toLowerCase();
        // } else {
        //   this.alerts = true;
        //   this.loading_status = false;
        // }
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var normalizer = 1;
        switch (this.item.uom) {
          case "LB":
            normalizer = 453;
            this.item.normalized_uom = "GR";
            break;
          case "LT":
            normalizer = 1000;
            this.item.normalized_uom = "ML";
            break;
          case "KG":
            normalizer = 1000;
            this.item.normalized_uom = "GR";
            break;
          case "OZ":
            normalizer = 28.34;
            this.item.normalized_uom = "GR";
            break;
          case "GL":
            normalizer = 3785.4;
            this.item.normalized_uom = "ML";
            break;
          case "ML":
            normalizer = 1;
            this.item.normalized_uom = "ML";
            break;
          case "UN":
            normalizer = 1;
            this.item.normalized_uom = "UN";
            break;
          case "GR":
            normalizer = 1;
            this.item.normalized_uom = "GR";
            break;
          default:
            normalizer = 1;
            this.item.normalized_uom = "UN";
        }
        this.item.normalized_size = parseFloat(this.item.size) * normalizer;
        this.item.cost_uom =
          parseFloat(this.item.price) / parseFloat(this.item.normalized_size);
        this.item.description = checkUTF8(this.item.description);
        this.item.product_options = JSON.stringify(this.options_selected);
        var qry = {
          account: this.$store.state.profile.account,
          table: this.table,
          data: [this.item],
        };
        if (this.compressed_image) {
          qry.photo = this.compressed_image;
        }

        webserver("put_table", qry, (data) => {
          console.log(data);
          this.compressed_image = null;
          this.loading_status = false;
          this.$emit("refresh", true);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      console.log(qry);
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", true);
    },
  },
  watch: {
    item: function (e) {
      this.valid = false;
      if (e.product_options) {
        if (JSON.parse(e.product_options)) {
          this.options_selected = JSON.parse(e.product_options);
        } else {
          this.options_selected = {};
        }
      } else {
        this.options_selected = {};
      }
      this.productPhoto = this.getImagePhoto(e.account, e.store, e.picture);
    },
  },
};
</script>

<style>
.btn-upload {
  top: 100px;
}
.bar-top {
  position: absolute;
  top: 0;
  width: 100%;
}
.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
</style>