<template>
  <v-sheet height="100%" width="100%">
    <template>
      <v-app-bar flat color="transparent" width="100%" class="fixed-bar-b">
        <v-btn
          color="warning"
          fab
          x-small
          dark
          @click="dialog_stores = !dialog_stores"
        >
          <v-icon>mdi-silverware-fork-knife</v-icon>
        </v-btn>
        <v-spacer></v-spacer>

        <v-icon large v-if="this.store.code" @click="edit_stores = !edit_stores"
          >mdi-store-edit-outline
        </v-icon>
        <v-spacer></v-spacer>
        <v-icon
          large
          v-if="this.store.picture"
          @click="
            openURL(getImagePhoto(store.account, store.code, store.picture))
          "
          >mdi-file-image-outline</v-icon
        >
        <v-spacer></v-spacer>
        <v-icon
          large
          v-if="this.store.menus"
          @click="openURL(getImageMenu(store.account, store.code, store.menus))"
          >mdi-file-image-outline</v-icon
        >
        <v-spacer></v-spacer>
        <v-icon
          large
          v-if="this.store.instagram"
          @click="openURL('https://www.instagram.com/' + store.instagram)"
          >mdi-instagram</v-icon
        >
        <v-spacer></v-spacer>
        <v-icon
          large
          v-if="this.store.facebook"
          @click="openURL('https://www.facebook.com/' + store.facebook)"
        >
          mdi-facebook
        </v-icon>

        <v-spacer></v-spacer>
        <v-icon large @click="create_pdf(store)"> mdi-file-pdf-box </v-icon>
        <v-btn
          color="warning"
          fab
          x-small
          dark
          @click="dialog_menu = !dialog_menu"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="warning" fab x-small dark @click="newForm()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container>
        <v-list>
          <v-list-item>
            <v-list-item-avatar size="120" rounded>
              <VueQr
                v-if="qrID"
                :text="qrID"
                :size="120"
                :callback="base64Qr"
                autoColor
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline">
                {{ this.store.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ this.store.address }} - {{ this.store.city }}<br />
                {{ this.store.mobile }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div v-for="(value, key) in categoryOrder" :key="key">
          <h1 class="text-center pt-6">
            {{ value }}
          </h1>
          <div class="mb-3"></div>
          <v-row fill-height align="center" justify-md="start">
            <v-col
              v-for="(child, index) in byCategories[value]"
              :key="index"
              cols="12"
              md="6"
              lg="4"
            >
              <v-card elevation="5" height="100%">
                <v-list-item three-line @click="editProduct(child)">
                  <v-list-item-avatar rounded size="80" v-if="child.picture">
                    <v-img
                      cover
                      :src="
                        getImagePhoto(child.account, child.store, child.picture)
                      "
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-black text-uppercase"
                      v-text="child.product_name"
                    />
                    <v-list-item-subtitle v-html="child.description" />
                    <v-list-item-title>
                      <span class="warning--text">
                        {{
                          "$ " + parseFloat(child.price).toLocaleString()
                        }}</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="orange--text">
                    <v-icon v-if="child.discount" color="grey lighten-1">
                      mdi-star-outline
                    </v-icon>

                    <v-icon v-else color="yellow darken-3"> mdi-star </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>

    <div v-if="categoryName">
      <v-row fill-height justify="center" v-if="$vuetify.breakpoint.smAndDown">
        <v-col
          v-for="(child, index) in category_selected"
          :key="index"
          cols="12"
          md="6"
        >
          <v-list-item three-line @click="editProduct(child)">
            <v-list-item-avatar size="80" rounded v-if="child.picture">
              <v-img
                cover
                :src="getImagePhoto(child.account, child.store, child.picture)"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="child.product_name"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="child.description"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-text="'$' + parseFloat(child.price).toLocaleString()"
            >
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>

      <v-row fill-height justify="center" v-if="!$vuetify.breakpoint.smAndDown">
        <v-col
          v-for="(child, index) in category_selected"
          :key="index"
          cols="12"
          md="6"
        >
          <v-list-item @click="editProduct(child)">
            <v-list-item-avatar
              horizontal
              size="120"
              rounded
              v-if="child.picture"
            >
              <v-img
                cover
                :src="getImagePhoto(child.account, child.code, child.picture)"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="child.product_name"
              ></v-list-item-title>

              <v-list-item-subtitle
                v-html="child.description"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              small
              v-text="'$' + parseFloat(child.price).toLocaleString()"
            >
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
    </div>

    <MenuForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      :categories="categories"
      @close="dialog = !dialog"
      :subcategories="subcategories"
      :brands="brands"
      :suppliers="suppliers"
      @refresh="refresh()"
    />
    <StoreParty
      table="stores"
      :item="store"
      :dialog="edit_stores"
      :newItem="newStore"
      :groups="groups"
      :categoriesLst="categories"
      @close="edit_stores = false"
      @refresh="
        edit_stores = false;
        get_stores();
      "
    />
    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
    </v-dialog>

    <v-navigation-drawer v-model="dialog_stores" fixed left width="450px" app>
      <v-toolbar dark color="#128C7E" class="fixed-bar">
        <v-toolbar-title>Restaurantes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_stores = !dialog_stores">mdi-arrow-left</v-icon>
      </v-toolbar>

      <v-data-table
        :headers="headers_store.true"
        :items="stores"
        :items-per-page="-1"
        item-key="code"
        sort-by="name"
        :search="search_store"
        mobile-breakpoint="0"
        class="table-cursor elevation-1"
        loading-text="Cargando ..."
        @click:row="change_menu"
      >
        <!-- <template v-slot:[`item.picture`]="{ item }" class="pa-0 ma-0">
          <v-avatar>
            <v-img
              v-if="item.picture"
              :src="getImagePhoto(item.account, item.code, item.picture)"
            ></v-img>
          </v-avatar>
        </template> -->
        <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
          <b>{{ item.name }}</b>
          <span>
            <small> ({{ item.group_name }})</small><br />
            <small> {{ item.address }} {{ item.mobile }} </small>
          </span>
        </template>
      </v-data-table>

      <v-bottom-navigation dark app>
        <v-toolbar color="#128C7E">
          <v-row justify="center">
            <v-col md="12" cols="12">
              <v-text-field
                v-model="search_store"
                hide-details
                append-icon="mdi-magnify"
                outlined
                rounded
                dense
                placeholder="Buscar restaurante ..."
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </v-bottom-navigation>
    </v-navigation-drawer>

    <v-dialog
      v-model="dialog_menu"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-list>
        <draggable v-model="categoryOrder">
          <template v-for="(item, index) in categories">
            <v-list-item :key="index">
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ item }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list-item>
          </template>
        </draggable>
      </v-list>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import createParty from "../utils/createStore.js";
import MenuForm from "../components/MenuForm.vue";
import StoreParty from "../components/store_Form.vue";
import createWebset from "../utils/createSetting.js";
import jsPDF from "jspdf";
import VueQr from "vue-qr";
export default {
  components: { MenuForm, StoreParty, VueQr },
  data() {
    return {
      categoryOrder: [],
      dialog_menu: false,
      qrID: "",
      qrb: null,
      edit_stores: false,
      newStore: false,
      groups: [],
      dialog_stores: true,
      stores: [],
      search_store: "",
      fontsLst: [
        "Amatic",
        "Architects",
        "Carattere",
        "Montserrat",
        "Roboto",
        "Shadows",
      ],
      theme: "Montserrat",
      menutype: false,
      whitPhoto: false,
      setting: false,
      table: "catalogue",
      search: "",
      errors: [],
      store: createParty(),
      head_bg_pic: "https://picsum.photos/1920/1080?random",
      valid: false,
      dialog: false,
      newItem: false,
      loading_status: false,
      category_selected: [],
      categoryName: null,
      byCategories: [],
      categories: [],
      subcategories: [],
      brands: [],
      suppliers: [],
      headers: [
        {
          text: "",
          align: "start",
          value: "picture",
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },

        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "number",
        },
      ],
      items: [],
      item: createItem(),
      model: "tab-0",
      tabs: [
        {
          name: "1st Tab",
          text: "This is a 1st tab",
        },
        {
          name: "2nd Tab",
          text: "This is a 2nd tab",
        },
        {
          name: "3rd Tab",
          text: "This is a 3rd tab",
        },
        {
          name: "4th Tab",
          text: "This is a 4th tab",
        },
      ],
      allProducts: [],
      headers_store: {
        false: [
          {
            text: "Grupo",
            align: "start",
            sortable: true,
            value: "group_name",
            dataType: "text",
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Dirección",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "Tel.",
            align: "end",
            value: "mobile",
            dataType: "text",
            width: 150,
          },
        ],
        true: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
        ],
      },
      webSet: createWebset(),
    };
  },
  mounted() {
    if (window.store) {
      this.store = window.store;
      this.item.account = this.store.account;
      this.item.store = this.store.code;
    }
    this.get_stores();
  },
  methods: {
    base64Qr(dataUrl) {
      this.qrb = dataUrl;
    },
    create_pdf() {
      var pdfName = this.store.name;
      var pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: [216, 273],
      });

      pdf.setFontSize(8);
      pdf.text(110, 5, this.store.name, "center");

      var y = 0;
      for (var i = 1; i < 4; i++) {
        var x = 0;
        for (var ii = 1; ii < 4; ii++) {
          pdf.roundedRect(5 + x, 10 + y, 66, 79, 2, 2, "f");
          pdf.addImage(this.qrb, "JPEG", 8 + x, 25 + y, 60, 60);
          x = x + 70;
        }
        y = y + 85;
      }

      y = 0;
      for (var iii = 1; iii < 4; iii++) {
        x = 0;
        for (var iiii = 1; iiii < 4; iiii++) {
          pdf.setTextColor(252, 132, 3);
          // pdf.setFont('Lato-Regular', 'normal');
          pdf.setFontType = "bold";
          pdf.setFontSize(22);
          pdf.text(38 + x, 20 + y, "Menú QR", "center");
          x = x + 70;
        }
        y = y + 85;
      }

      pdf.addPage();
      pdf.roundedRect(5, 10, 200, 120, 10, 10, "f");
      pdf.addImage(this.qrb, "JPEG", 15, 20, 100, 100);

      //var blob = pdf.output("blob");
      //var objectUrl = URL.createObjectURL(blob);
      //this.pdfFile = objectUrl;

      pdf.save(pdfName + ".pdf");
    },
    change_menu(e) {
      this.store = e;
      this.qrID = "https://qr4.restaurant/" + e.code;
      if (this.$vuetify.breakpoint.smAndDown) {
        this.dialog_stores = false;
      }

      if (e.setting !== null) {
        this.webSet = JSON.parse(e.setting);
      } else {
        this.webSet = createWebset();
      }
      console.log(this.webSet);
      this.get_items();
    },
    get_stores() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "stores",
        filters: [],
      };
      //console.log(qry);
      if (this.$store.state.profile.user_access === "Sales") {
        qry.filters.push({
          field: "agent",
          operator: "=",
          value: this.$store.state.profile.name,
        });
      }
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        //console.log(data);
        this.loading_status = false;
        this.stores = data;
      });
    },
    editProduct(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    get_items() {
      this.items = [];
      this.byCategories = [];
      this.loading_status = true;
      var qry = {
        account: this.store.account,
        table: this.table,
        filters: [{ field: "store", value: this.store.code, operator: "=" }],
      };
      //console.log(qry);
      webserver("get_table", qry, (data) => {
        data.forEach((rg) => {
          if (rg.brand) rg.brand = rg.brand.toUpperCase();
          if (rg.supplier) rg.supplier = rg.supplier.toUpperCase();
          if (rg.cost_uom) rg.cost_uom = parseFloat(rg.cost_uom);
        });
        this.allProducts = data;

        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));
        for (const [key] of Object.entries(ctgry)) {
          ctgry[key].sort(function (a, b) {
            var textA = a.product_name.toUpperCase();
            var textB = b.product_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        this.byCategories = ctgry;
        if (this.webSet.categories_order) {
          this.categoryOrder = this.webSet.categories_order;
        } else {
          this.categoryOrder = Object.keys(ctgry).sort();
        }
        this.categories = Object.keys(ctgry).sort();

        this.category_selected = ctgry;

        // if (this.categoryName) {
        //   this.category_selected = ctgry[this.categoryName];
        // } else {
        //   this.category_selected = ctgry[Object.keys(ctgry)[0]];
        // }

        this.head_bg_pic = this.getImagePhoto(
          this.store.account,
          this.store.code,
          this.store.picture
        );

        var sbctgry = data.reduce(function (r, a) {
          r[a.subcategory] = r[a.subcategory] || [];
          r[a.subcategory].push(a);
          return r;
        }, Object.create(null));
        this.subcategories = Object.keys(sbctgry).sort();

        var brnd = data.reduce(function (r, a) {
          r[a.brand] = r[a.brand] || [];
          r[a.brand].push(a);
          return r;
        }, Object.create(null));
        this.brands = Object.keys(brnd).sort();

        var spplr = data.reduce(function (r, a) {
          r[a.supplier] = r[a.supplier] || [];
          r[a.supplier].push(a);
          return r;
        }, Object.create(null));
        this.suppliers = Object.keys(spplr).sort();

        this.loading_status = false;
        this.items = data;
        //if (data.length === 0) this.newForm();
      });
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.store.account;
      this.item.store = this.store.code;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    changeMenu(e) {
      // this.category_selected = this.byCategories[e];
      // this.categoryName = e;
      this.head_bg_pic = this.getbgpic(e);
      this.$vuetify.goTo("#tab-" + e.replace(/\s/g, ""));
    },
    refresh() {
      //console.log("refresh");
      this.get_items();
      this.dialog = false;
      this.categoryName = null;
    },
    getImagePhoto: function (account, store, src) {
      var url =
        "https://mayorista.app/photos/" + account + "/" + store + "/" + src;
      return url;
    },
    getImageMenu: function (account, code, src) {
      var url =
        "https://smartchef.pro/menus/" + account + "/" + code + "/" + src;
      return url;
    },
    bgjpg() {
      return "https://picsum.photos/1920/1080?random";
    },
    getbgpic(ctgry) {
      var phtslst;
      if (ctgry) {
        phtslst = this.byCategories[ctgry];
      } else {
        phtslst = this.allProducts;
      }

      phtslst = phtslst.filter(function (el) {
        return el.picture !== "";
      });

      if (phtslst.length > 0) {
        var rdm = phtslst[(phtslst.length * Math.random()) | 0];

        if (rdm.picture) {
          return this.getImagePhoto(rdm.account, rdm.store, rdm.picture);
        } else {
          if (window.store.picture) {
            return this.getImagePhoto(
              window.store.account,
              window.store.store,
              window.store.picture
            );
          }
          return "https://picsum.photos/1920/1080?random";
        }
      } else {
        return "https://picsum.photos/1920/1080?random";
      }
    },

    openURL(e) {
      window.open(e, "", "width=400,height=800");
    },
  },
  watch: {
    item: function (e) {
      this.storePhoto = this.getImagePhoto(e.account, e.store, e.picture);
    },
  },
};
</script>

<style>
.Montserrat {
  font-family: "Montserrat", sans-serif !important;
}
.Roboto {
  font-family: "Roboto Condensed", sans-serif !important;
}

.Architects {
  font-family: "Architects Daughter", cursive !important;
}

.Shadows {
  font-family: "Shadows Into Light", cursive !important;
}

.Amatic {
  font-family: "Amatic SC", cursive !important;
}

.Carattere {
  font-family: "Carattere", cursive !important;
}

.fixed-bar-b {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 56px;
  z-index: 2;
}

.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 70px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 2;
}

.tshadow {
  position: absolute;
  overflow: auto !important;
  text-shadow: black -1px 2px 6px;
  padding: 10px;
  color: white;
  font-size: 1.5em;
  width: 100%;
  text-align: center;
  bottom: 5px;
}

.file-input {
  display: none;
}
.centered-input input {
  text-align: center;
}
.table-cursor tbody tr:hover {
  cursor: pointer;
}
.text-start {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>

